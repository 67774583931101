@import "../../../../variables.scss";

$QuestionBoxMarginBottom: 30px;

.StepContainer {
  :global {
    .row {
      .d-grid {
        @media screen and (max-width: #{$MOBILE}) {
          width: 46%;
        }
        button {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.FirstQuestionBox {
  margin-top: 40px;
  margin-bottom: $QuestionBoxMarginBottom;
}

.QuestionBox {
  margin-bottom: $QuestionBoxMarginBottom;
}

.ButtonBox {
  padding-left: 30px;
  margin-top: 20px;
}

@media screen and (max-width: #{$MOBILE}) {
  .Introduction {
    font-size: 13px;
    word-break: keep-all;
  }

  :global .alert {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.ModeBox {
  margin-bottom: 50px;

  .Title {
    font-family: "Pretendard";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: rgb(33, 37, 41);
  }
}
