.AppBar {
  padding-top: 10px;
  padding-bottom: 20px;

  img {
    width: 36px;
    height: 36px;
  }

  .Title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
  }
}
