.FooterContainer {
  border-top: solid 1px #e5e5e5;
  margin-top: 30px;
}

.Footer {
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;

  p {
    color: #777;
    margin-top: 10px;
  }
}
