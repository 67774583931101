@import "../../../../variables.scss";

$border-color: #e5e5e5;

.StepContainer {
  :global {
    .row {
      .d-grid {
        button {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.FaceShapeButton {
  width: 100%;
  padding: 15px;
  border: 1px solid $border-color;
  border-radius: 6px;
  background-color: white;
  color: #2b2b2b;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;

  img {
    height: 120px;
    margin-bottom: 10px;
  }

  .Title {
    font-weight: 700;
    margin-top: 10px;
  }

  .FaceShapeTextBox {
    width: 100%;
    margin-top: 15px;
    .FaceShapeText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px !important;
      span {
        color: #2b2b2b;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
      }
    }

    .StudyText {
      font-size: 13px;
      color: #2b2b2b;
      font-weight: normal;
      word-break: keep-all;
      line-height: 1.4;
      margin-top: 15px;

      p {
        color: #000;
        margin-bottom: 0.5rem;
      }

      ul {
        padding-left: 1rem;
      }
    }
  }
}

.HeadShapeRow {
  > div {
    margin-bottom: 10px;
  }

  button {
    img {
      width: 100%;
      max-width: 110px;
      object-fit: contain;
    }
  }

  .SideHeadShapeButton {
    img {
      max-height: 120px;
    }
  }
}

.FrontHeadShapeButton {
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  background: #ffffff;
  border: 1px solid $border-color;
  border-radius: 6px;
  margin-bottom: 10px;

  .FrontHeadShapeTextContainer {
    margin-top: 25px;
    .FrontHeadShapeTextBox {
      width: 100%;
      margin-top: 17px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      & > div {
        padding: 0px;
      }
      .FrontHeadShapeTextTitle {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    border: 1px solid #000000;
  }
}

.SideHeadShapeButton {
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  background: #ffffff;
  border: 1px solid $border-color;
  border-radius: 6px;
  .SideHeadShapeTextContainer {
    margin-top: 25px;
    .SideHeadShapeTextBox {
      width: 100%;
      margin-top: 17px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .SideHeadShapeTextTitle {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    span {
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }
  }
  &:active,
  &:hover,
  &:focus {
    outline: none;
    border: 1px solid #000000;
  }
}

.HeadImageButtonActive {
  border: 1px solid #000 !important;
  outline: none !important;
  box-shadow: 1px 1px 1px 1px #e5e5e5;
}

.FirstQuestionBox {
  margin-top: 30px;
  margin-bottom: 30px;
}

.QuestionBox {
  margin-bottom: 30px;
}

.ButtonBox {
  margin-top: 20px;
  font-size: 14px;
}

.QuestionBoxMobilePadding {
  small {
    margin-top: 10px;
    color: #2b2b2b;
    font-size: 13px;

    ul {
      margin-bottom: 0;
    }
  }
}
