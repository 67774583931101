.ModalHeader {
  display: flex;
  justify-content: flex-end;
  background-color: #000000;
  color: #ffffff;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  text-align: center;

  & > span {
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
  }

  .ModalCloseButton {
    font-weight: 500;
    color: #ffffff;
    outline: none;
    border: none;
    background-color: transparent;
  }
}

.CarouselBody {
  margin: 80px 162px;
}

.CarouselFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .CarouselActiveNumber {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin: 0px 20px;
    span:last-child {
      color: #a3a3a3;
    }
  }
}
