@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

@import "./variables.scss";

body {
  margin: 0;
  font-size: 14px;
  font-family: "Pretendard", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
}

.nav-item {
  font-size: 15px;
}

.nav-link.active {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: rgba(255, 229, 100, 0.25);
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.btn-active,
.btn-active:focus,
.btn-active:hover {
  background-color: black;
  color: white;
}

.btn-inactive,
.btn-inactive:focus,
.btn-inactive:hover {
  background-color: white;
  color: black;
  border-color: #5e5e5e;
}

.btn-active-of-custom-color,
.btn-active-of-custom-color:focus,
.btn-active-of-custom-color:hover {
  color: white;
  border-width: 2px;
}

.btn-inactive-of-custom-color,
.btn-inactive-of-custom-color:focus,
.btn-inactive-of-custom-color:hover {
  color: white;
  border-color: transparent;
  border-width: 2px;
}

.modal-padding-top {
  margin-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

.modal-padding {
  padding-left: 60px;
  padding-right: 60px;
}

.modal-body {
  overflow-x: hidden;
}

.sticky-header {
  position: sticky;
  z-index: 999;
  top: -34px;
}

@media screen and (max-width: 700px) {
  .modal-padding-top {
    margin-top: 20px;
    padding: 0;
  }

  .modal-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .w-45 {
    width: 45%;
  }
}

@media screen and (min-width: 701px) {
  .sticky-header {
    top: -78px;
  }
}

.container.body {
  min-height: 500px;
}

#action-button-create {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1;
}

div.MuiTabs-root {
  min-height: 36px;
}

button.MuiTab-root {
  min-width: 48px;
  min-height: 36px;
}

th {
  font-weight: 600;
}

th, td {
  word-break: keep-all;
}