@import "../../../../variables.scss";

.Section {
  margin-top: 36px;
  background-color: black;
  .Box {
    width: 100%;
    height: 50px;
    font-weight: 600;
    text-align: center;
    line-height: 50px;
    font-size: 21px;
    color: #fff;
  }
}

@media screen and (max-width: #{$MOBILE}) {
  .Section {
    .Box {
      font-size: 14px;
    }
  }
}
