.DatePickerCell {
  text-align: center;
  input {
    height: 19px;
    padding: 6px 0;
    text-align: right;
  }
}

.DesignerButtonList {
  text-align: center;
  margin-top: 10px;

  button {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 4px;
  }
}
