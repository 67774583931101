@import "../../../../variables.scss";

.StepNumber {
  margin-right: 10px;
  line-height: 40px;
  font-size: 18px;
}

.StepTitle {
  font-size: 18px;
}

@media screen and (max-width: #{$MOBILE}) {
  .StepNumber {
    font-size: 16px;
  }

  .StepTitle {
    font-size: 16px;
  }
}

@media screen and (min-width: #{$TABLET}) {
  .QuestionBoxMobilePadding {
    margin-bottom: 20px;
  }
}
