.ProfileHeading {
  text-align: center;

  h6 {
    margin-top: 10px;
    margin-bottom: 2px;
  }

  small {
    color: "#2b2b2b";
  }

  .BadgeArea {
    margin-top: 5px;
  }

  .ButtonArea {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
