.QuestionMark {
  font-size: 16px;
  font-weight: bold;
  height: 19px;
  padding-top: 3px;
  display: flex;
  align-items: center;
  width: 30px;
}

.QuestionTitle {
  font-family: "Pretendard";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  height: 19px;
}
