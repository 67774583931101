@import "../../../../variables.scss";

.StepContainer {
  :global {
    .row {
      .d-grid {
        > button {
          margin-bottom: 15px;
          position: relative;
          height: 38px;
        }
      }
    }
  }
}

.SolutionArea input,
textarea {
  border: none;
  resize: none;
}

.SolutionArea {
  background: #ffffff;
  border: 1px solid #232323;
  padding: 30px 76px 84px 62px;
  margin-top: 60px;
  margin-bottom: 40px;
  .SolutionTitle {
    border-bottom: 2px solid #000000;
    padding-bottom: 10px;
    margin-bottom: 34px;
    width: 100%;
    font-size: 21px;
  }
  .SolutionContent {
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 20px;

    input::placeholder,
    textarea::placeholder {
      color: #bbbbbb;
    }

    ul {
      margin-top: 5px;
      margin-bottom: 0;
      line-height: 1.4;
    }

    &:nth-child(1) {
      margin-bottom: 15px;
    }
    .SolutionQuestionFirstGroup {
      margin: 30px 0px 20px 0px;
      > div {
        width: 100%;
        align-items: center;
        margin-top: 10px;
      }

      .HairStatusChips {
        margin-top: 5px;
      }

      input,
      textarea {
        outline: none;
        width: 100%;
      }
    }
    .SolutaionQuestionLastGroup {
      margin: 10px 0px 30px 0px;
      .SolutaionQuestionLastGroupQuestionBox {
        margin-bottom: 30px;

        > div {
          input,
          textarea {
            width: 100%;
            outline: none;
          }

          textarea {
            padding: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: #{$TABLET}) {
  .SolutionArea {
    padding: 12px;
    margin: 15px;
  }
}
